import { graphql, useStaticQuery } from "gatsby"
import { DatoLocale } from "../types"

/**
 * DatoCMS record ID
 */
type ArticleCategoryId = string

type ArticleCategoryMap = Map<ArticleCategoryId, string>

const store = new Map<DatoLocale, ArticleCategoryMap>()

export function useArticleCategories(locale: DatoLocale): ArticleCategoryMap {
  const data = useStaticQuery<Queries.ArticleCategoriesQuery>(graphql`
    query ArticleCategories {
      cat: allDatoCmsArticleCategory {
        nodes {
          id: originalId
          names: _allNameLocales {
            locale
            value
          }
        }
      }
    }
  `)

  const cached = store.get(locale)
  if (cached) {
    return cached
  }

  const result = new Map<ArticleCategoryId, string>()

  data.cat.nodes.forEach(cat => {
    if (!cat.id || !cat.names) {
      return
    }
    const label = cat.names.find(name => name?.locale === locale)?.value
    if (!label) {
      return
    }
    result.set(cat.id, label)
  })
  store.set(locale, result)

  return result
}
