import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { useArticleCategories } from "../../../hooks/useArticleCategories"
import useArticlePath from "../../../hooks/useArticlePath"
import { useArticleTags } from "../../../hooks/useArticleTag"
import { useArticles } from "../../../hooks/useArticles"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import { useToggleList } from "../../../hooks/useToggleList"
import SubHeading from "../../atoms/SubHeading"
import ToggleButton from "../../atoms/ToggleButton"
import BgColor from "../../layouts/BgColor"
import ArticleFilter from "../../molecules/ArticleFilter"
import * as styles from "./DatoArticleFinder.module.scss"

const DatoArticleFinder = (props: Queries.DatoArticleFinderFragment) => {
  //console.log('Rendering DatoArticleFinder', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("article", locale)
  const getArticlePath = useArticlePath()
  const allArticles = useArticles(locale)
  const categoryMap = useArticleCategories(locale)
  const tagMap = useArticleTags(locale)

  const [categories, toggleCategory, clearCategories] = useToggleList([])
  const [tags, toggleTag, clearTags] = useToggleList([])

  let articles = allArticles
  // filter by categories
  if (categories?.length) {
    articles = articles.filter(article => article.articleCategory && categories.includes(article.articleCategory.id))
  }
  // filter by availability on website
  articles = articles.filter(article => !!getArticlePath(article.id))

  /* Filtteröidään näkyviin vain valittujen kategorioiden artikkelien käyttämät tagit */
  const activeTags = new Set<string>()
  articles.forEach(article => {
    article.articleTags?.forEach(articleTag => {
      if (articleTag?.id && !activeTags.has(articleTag.id)) {
        activeTags.add(articleTag.id)
      }
    })
  })

  // filter by tags
  if (tags?.length) {
    articles = articles.filter(article =>
      tags.find(tag => article.articleTags?.find(articleTag => articleTag?.id === tag))
    )
  }

  function onClearFiltersIntent() {
    clearTags()
    clearCategories()
  }

  return (
    <div className={styles.container}>
      <SubHeading text={props.title} />

      <BgColor color="BrandGreyWarmLight" className={styles.content}>
        {props.text && <p>{props.text}</p>}

        <div className={styles.buttons}>
          {Array.from(categoryMap).map(([id, label]) => (
            <span key={id} className={styles.categoryButton}>
              <ToggleButton label={label} onClick={() => toggleCategory(id)} active={categories.includes(id)} />
            </span>
          ))}
        </div>

        <div className={styles.buttons}>
          {Array.from(tagMap).map(([id, label]) => {
            return (
              <ToggleButton
                key={id}
                size="flat"
                label={label}
                onClick={() => toggleTag(id)}
                active={tags.includes(id)}
                disabled={!activeTags.has(id)}
              />
            )
          })}
        </div>
        {(!!categories.length || !!tags.length) && (
          <button className={styles.clearButton} onClick={() => onClearFiltersIntent()}>
            {t("clearFilters", "Clear all filters")}
          </button>
        )}
      </BgColor>

      <ArticleFilter articles={articles} />
    </div>
  )
}

export default DatoArticleFinder

export const query = graphql`
  fragment DatoArticleFinder on DatoCmsArticleFinder {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    text
  }
`
